import { useState } from "react";

const PmIcon = () => {
    const [color, setColor] = useState('#94aab9');
    
    return(
        <div className="flex justify-center items-center">
            <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg" className="cursor-pointer" onMouseEnter={() => {setColor('#FF6C37')}} onMouseLeave={() => {setColor('#94aab9')}}>
                <g clip-path="url(#clip0_343_24)">
                <path d="M31.3906 0H9.60938C4.30226 0 0 4.30226 0 9.60938V31.3906C0 36.6977 4.30226 41 9.60938 41H31.3906C36.6977 41 41 36.6977 41 31.3906V9.60938C41 4.30226 36.6977 0 31.3906 0Z" fill={color} />
                <path d="M27.0762 14.1918L18.9819 22.2862L16.6992 20.0037C24.6595 12.0436 25.446 12.7342 27.0762 14.1918Z" fill="#EEEEEE"/>
                <path d="M18.982 22.4779C18.9243 22.4779 18.886 22.4589 18.8476 22.4204L16.5458 20.1379C16.5276 20.1206 16.5132 20.0998 16.5033 20.0767C16.4934 20.0536 16.4883 20.0288 16.4883 20.0037C16.4883 19.9786 16.4934 19.9537 16.5033 19.9307C16.5132 19.9076 16.5276 19.8868 16.5458 19.8695C24.6403 11.7749 25.4841 12.5422 27.1914 14.0766C27.2298 14.1151 27.2489 14.1535 27.2489 14.211C27.2489 14.2686 27.2298 14.3069 27.1914 14.345L19.0969 22.4204C19.0779 22.4589 19.0202 22.4779 18.982 22.4779ZM16.9678 20.0037L18.982 22.0176L26.7886 14.211C25.3693 12.945 24.4101 12.5614 16.9678 20.0037Z" fill={color}/>
                <path d="M21.3025 24.6072L19.0967 22.4014L27.1911 14.3071C29.3587 16.4935 26.117 20.0421 21.3025 24.6072Z" fill="#EEEEEE"/>
                <path d="M21.3023 24.7991C21.2447 24.7991 21.2065 24.7799 21.1681 24.7414L18.9623 22.5356C18.9238 22.4973 18.9238 22.4589 18.9238 22.4014C18.9238 22.3437 18.943 22.3055 18.9815 22.267L27.0758 14.1727C27.0931 14.1545 27.1139 14.1401 27.137 14.1302C27.1601 14.1203 27.185 14.1152 27.2101 14.1152C27.2352 14.1152 27.26 14.1203 27.2831 14.1302C27.3062 14.1401 27.327 14.1545 27.3444 14.1727C27.5893 14.3993 27.7828 14.6758 27.9118 14.9835C28.0409 15.2912 28.1025 15.623 28.0925 15.9565C28.0542 18.0855 25.6373 20.7901 21.4557 24.7414C21.3982 24.7799 21.3406 24.7991 21.3023 24.7991ZM19.3651 22.4014C20.5925 23.648 21.1106 24.1469 21.3023 24.3386C24.5248 21.2697 27.6512 18.1239 27.6704 15.9565C27.6897 15.4578 27.498 14.9591 27.1717 14.5754L19.3651 22.4014Z" fill={color}/>
                <path d="M16.7373 20.0612L18.3677 21.6916C18.406 21.73 18.406 21.7683 18.3677 21.8067C18.3485 21.8259 18.3485 21.8259 18.3294 21.8259L14.9533 22.5548C14.7809 22.574 14.6274 22.4589 14.589 22.2862C14.5698 22.1903 14.6082 22.0945 14.6659 22.0369L16.6222 20.0804C16.6606 20.042 16.7181 20.0227 16.7373 20.0612Z" fill="#EEEEEE"/>
                <path d="M14.8962 22.7467C14.6085 22.7467 14.3975 22.5164 14.3975 22.2286C14.3975 22.0945 14.4551 21.9602 14.5511 21.8642L16.5074 19.9078C16.6225 19.8118 16.7761 19.8118 16.8911 19.9078L18.5215 21.5382C18.6365 21.6341 18.6365 21.8067 18.5215 21.9217C18.483 21.9602 18.4448 21.9794 18.3871 21.9986L15.0113 22.7275C14.9729 22.7275 14.9345 22.7467 14.8962 22.7467ZM16.6802 20.2721L14.8004 22.1519C14.762 22.1903 14.7428 22.2478 14.7812 22.3053C14.8004 22.363 14.8577 22.3822 14.9154 22.363L18.0803 21.6724L16.6802 20.2721Z" fill={color}/>
                <path d="M31.3535 9.70356C30.1259 8.5144 28.1502 8.55268 26.9609 9.79949C25.7717 11.0461 25.8102 13.0026 27.0568 14.1919C27.549 14.6675 28.1847 14.9668 28.8648 15.0431C29.5449 15.1194 30.2311 14.9685 30.8165 14.6139L28.6297 12.4272L31.3535 9.70356Z" fill="#EEEEEE"/>
                <path d="M29.2053 15.2469C27.3832 15.2469 25.9062 13.7699 25.9062 11.9477C25.9062 10.1254 27.3832 8.64844 29.2053 8.64844C30.0493 8.64844 30.8741 8.97452 31.4879 9.56918C31.5263 9.60761 31.5455 9.64605 31.5455 9.70339C31.5455 9.76104 31.5263 9.79948 31.4879 9.83776L28.8984 12.4272L30.9316 14.4603C31.0085 14.5372 31.0085 14.6522 30.9316 14.7289L30.8934 14.7674C30.3946 15.0741 29.8 15.2469 29.2053 15.2469ZM29.2053 9.05139C27.5941 9.05139 26.2898 10.3555 26.309 11.9669C26.309 13.578 27.6132 14.8824 29.2245 14.8633C29.6658 14.8633 30.1068 14.7672 30.5096 14.5563L28.4957 12.5615C28.4572 12.5231 28.4382 12.4847 28.4382 12.4272C28.4382 12.3695 28.4572 12.3312 28.4957 12.293L31.066 9.72261C30.5481 9.28154 29.8959 9.05139 29.2053 9.05139Z" fill={color}/>
                <path d="M31.4112 9.76103L31.3729 9.7226L28.6299 12.4272L30.7974 14.5947C31.0085 14.4603 31.2193 14.3071 31.3921 14.1344C31.6827 13.8498 31.9139 13.5102 32.0723 13.1356C32.2308 12.7609 32.3133 12.3585 32.315 11.9517C32.3168 11.5449 32.2378 11.1418 32.0827 10.7658C31.9275 10.3897 31.6993 10.0482 31.4112 9.76103Z" fill="#EEEEEE"/>
                <path d="M30.817 14.8056C30.7593 14.8056 30.721 14.7864 30.6826 14.748L28.496 12.5614C28.4575 12.5229 28.4385 12.4846 28.4385 12.4271C28.4385 12.3695 28.4575 12.331 28.496 12.2928L31.2196 9.56931C31.2965 9.49244 31.4115 9.49244 31.4882 9.56931L31.5458 9.60775C32.8309 10.8927 32.8309 12.9643 31.565 14.2688C31.3732 14.4605 31.1621 14.6331 30.9319 14.7673C30.8745 14.7866 30.8362 14.8056 30.817 14.8056ZM28.8988 12.4273L30.8362 14.3646C30.9896 14.2688 31.143 14.1344 31.258 14.0193C32.3514 12.9259 32.4089 11.1422 31.354 9.99133L28.8988 12.4273Z" fill={color}/>
                <path d="M27.4022 14.5369C27.1807 14.3158 26.8806 14.1917 26.5677 14.1917C26.2549 14.1917 25.9548 14.3158 25.7333 14.5369L18.502 21.7683L19.7105 22.9766L27.3637 16.2634C27.8624 15.8412 27.9007 15.0931 27.4789 14.5945C27.4404 14.5753 27.4212 14.5561 27.4022 14.5369Z" fill="#EEEEEE"/>
                <path d="M19.6913 23.1685C19.6338 23.1685 19.5954 23.1493 19.5569 23.111L18.3486 21.9027C18.3304 21.8853 18.3159 21.8645 18.306 21.8414C18.2961 21.8183 18.291 21.7935 18.291 21.7684C18.291 21.7433 18.2961 21.7184 18.306 21.6953C18.3159 21.6722 18.3304 21.6514 18.3486 21.6341L25.5798 14.4027C25.7067 14.2751 25.8577 14.1738 26.0239 14.1046C26.1902 14.0355 26.3684 13.9999 26.5485 13.9999C26.7285 13.9999 26.9068 14.0355 27.073 14.1046C27.2393 14.1738 27.3902 14.2751 27.5172 14.4027C27.6449 14.5297 27.7462 14.6806 27.8153 14.8469C27.8844 15.0131 27.92 15.1914 27.92 15.3714C27.92 15.5515 27.8844 15.7297 27.8153 15.896C27.7462 16.0622 27.6449 16.2132 27.5172 16.3401L27.4597 16.3976L19.8063 23.111C19.7872 23.1493 19.7488 23.1685 19.6913 23.1685ZM18.7706 21.7683L19.7105 22.7083L27.2295 16.1098C27.6514 15.7645 27.6898 15.1316 27.3445 14.7097C26.9992 14.2877 26.3663 14.2494 25.9443 14.5944C25.9252 14.6136 25.906 14.6329 25.8676 14.6521L18.7706 21.7683Z" fill={color}/>
                <path d="M12.9593 29.9973C12.8825 30.0356 12.8442 30.1123 12.8632 30.189L13.1895 31.5702C13.2664 31.7619 13.151 31.9922 12.9401 32.0497C12.7867 32.1072 12.6142 32.0497 12.5183 31.9347L10.4082 29.8438L17.2943 22.9578L19.6728 22.996L21.2839 24.6074C20.9004 24.9335 18.5794 27.1776 12.9593 29.9973Z" fill="#EEEEEE"/>
                <path d="M12.805 32.2412C12.6516 32.2412 12.4982 32.1837 12.4022 32.0687L10.3114 29.9779C10.273 29.9396 10.2539 29.9012 10.2539 29.8437C10.2539 29.7862 10.2731 29.7478 10.3116 29.7093L17.1975 22.8234C17.2359 22.785 17.2934 22.7657 17.3319 22.7657L19.7103 22.8042C19.7678 22.8042 19.8061 22.8234 19.8444 22.8617L21.4557 24.4729C21.4942 24.5113 21.5132 24.5688 21.5132 24.6263C21.5132 24.6839 21.4942 24.7222 21.4365 24.7607L21.3023 24.8758C19.2691 26.6595 16.5069 28.4434 13.0735 30.1506L13.3997 31.5124C13.4572 31.7617 13.342 32.0303 13.1119 32.1645C12.9967 32.222 12.901 32.2412 12.805 32.2412ZM10.695 29.8437L12.6708 31.8002C12.7282 31.8961 12.8433 31.9344 12.9392 31.8769C13.0352 31.8194 13.0736 31.7042 13.0161 31.6083L12.69 30.2273C12.6516 30.0546 12.7282 29.9012 12.8817 29.8245C16.2767 28.1174 19.0197 26.3528 21.0337 24.6072L19.6144 23.1878L17.4086 23.1493L10.695 29.8437Z" fill={color}/>
                <path d="M8.77705 31.4933L10.4267 29.8437L12.8819 32.2989L8.96891 32.0305C8.79627 32.0112 8.68111 31.8578 8.70033 31.685C8.70033 31.6083 8.71939 31.5316 8.77705 31.4933Z" fill="#EEEEEE"/>
                <path d="M12.8822 32.4715L8.95005 32.2029C8.66225 32.1837 8.47039 31.9345 8.4896 31.6467C8.50882 31.5316 8.54726 31.4166 8.64319 31.3399L10.2928 29.6902C10.3101 29.6721 10.3309 29.6576 10.354 29.6477C10.3771 29.6378 10.4019 29.6327 10.427 29.6327C10.4521 29.6327 10.477 29.6378 10.5 29.6477C10.5231 29.6576 10.5439 29.6721 10.5612 29.6902L13.0164 32.1454C13.0741 32.2029 13.0933 32.2797 13.0549 32.3565C13.0164 32.4332 12.9588 32.4715 12.8822 32.4715ZM10.427 30.1123L8.91178 31.6275C8.85412 31.6659 8.85412 31.7619 8.91178 31.8001C8.931 31.8194 8.95021 31.8386 8.98849 31.8386L12.3835 32.0687L10.427 30.1123ZM16.6225 23.8401C16.5073 23.8401 16.4306 23.7441 16.4306 23.6482C16.4306 23.5907 16.4499 23.5524 16.4883 23.514L18.3488 21.6534C18.3661 21.6352 18.387 21.6207 18.41 21.6108C18.4331 21.6009 18.458 21.5958 18.4831 21.5958C18.5082 21.5958 18.5331 21.6009 18.5562 21.6108C18.5793 21.6207 18.6001 21.6352 18.6174 21.6534L19.8258 22.8618C19.8833 22.9193 19.9025 22.9768 19.8833 23.0537C19.8641 23.1112 19.8066 23.1687 19.73 23.1879L16.6609 23.8401H16.6225ZM18.483 22.0562L17.2172 23.3223L19.2886 22.8809L18.483 22.0562Z" fill={color}/>
                <path d="M19.6721 22.9959L17.5622 23.4563C17.4088 23.4947 17.2552 23.3988 17.2169 23.2454C17.1977 23.1495 17.2169 23.0535 17.2936 22.9768L18.4636 21.8067L19.6721 22.9959Z" fill="#EEEEEE"/>
                <path d="M17.5245 23.6482C17.4613 23.6489 17.3987 23.6369 17.3402 23.613C17.2817 23.5892 17.2286 23.5539 17.184 23.5092C17.1393 23.4646 17.104 23.4114 17.0801 23.353C17.0563 23.2945 17.0443 23.2319 17.0449 23.1687C17.0449 23.0345 17.1024 22.9193 17.1792 22.8236L18.3493 21.6535C18.3666 21.6353 18.3874 21.6208 18.4105 21.6109C18.4336 21.601 18.4584 21.5959 18.4835 21.5959C18.5087 21.5959 18.5335 21.601 18.5566 21.6109C18.5797 21.6208 18.6005 21.6353 18.6178 21.6535L19.8262 22.8619C19.8837 22.9193 19.9029 22.9768 19.8837 23.0537C19.8645 23.1112 19.807 23.1687 19.7304 23.1879L17.6204 23.6482H17.5245ZM18.4835 22.0563L17.4477 23.092C17.4095 23.1304 17.4095 23.1687 17.4285 23.2072C17.4477 23.2454 17.4862 23.2646 17.5437 23.2646L19.3083 22.8809L18.4835 22.0563ZM31.4308 11.6022C31.3925 11.4872 31.2582 11.4297 31.143 11.4682C31.028 11.5063 30.9705 11.6407 31.009 11.7558C31.009 11.775 31.028 11.7943 31.028 11.8133C31.143 12.0436 31.1049 12.3313 30.9513 12.5422C30.8746 12.6383 30.8938 12.7725 30.9705 12.8492C31.0665 12.9258 31.2007 12.9069 31.2774 12.8108C31.5652 12.4463 31.6227 11.986 31.4308 11.6022Z" fill={color}/>
                </g>
                <defs>
                <clipPath id="clip0_343_24">
                <rect width="41" height="41" fill="white"/>
                </clipPath>
                </defs>
            </svg>
        </div>

    );
}

export default PmIcon;