import { useState } from "react";

const MongoIcon = () => {
    const [color, setColor] = useState(['#94aab9', '#fff', '#fff', '#fff']);
    
    return(
        <div className="flex justify-center items-center">
            <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg" className="cursor-pointer" onMouseEnter={() => {setColor(['#023430', '#10AA50', '#B8C4C2', '#12924F'])}} onMouseLeave={() => {setColor(['#94aab9', '#fff', '#fff', '#fff'])}}>
                <g clip-path="url(#clip0_343_14)">
                <path d="M29.8594 0H9.14062C4.0924 0 0 4.0924 0 9.14062V29.8594C0 34.9076 4.0924 39 9.14062 39H29.8594C34.9076 39 39 34.9076 39 29.8594V9.14062C39 4.0924 34.9076 0 29.8594 0Z" fill={color[0]} />
                <path d="M26.0771 16.3908C24.4719 9.30973 21.1264 7.42462 20.273 6.09207C19.921 5.50505 19.6093 4.89483 19.3399 4.26562C19.2948 4.8948 19.2119 5.2909 18.6772 5.76804C17.6036 6.72521 13.044 10.4409 12.6602 18.4866C12.3026 25.9883 18.175 30.6139 18.9508 31.0921C19.5474 31.3857 20.2739 31.0984 20.6284 30.8289C23.4591 28.8862 27.3268 23.7068 26.0802 16.3908" fill={color[1]} />
                <path d="M19.5831 27.0975C19.4354 28.9543 19.3295 30.0332 18.9541 31.0943C18.9541 31.0943 19.2004 32.8619 19.3735 34.7344H19.9858C20.1318 33.4158 20.3548 32.1068 20.6536 30.8143C19.861 30.4243 19.6136 28.7269 19.5831 27.0975Z" fill={color[2]} />
                <path d="M20.6525 30.8153C19.8515 30.4453 19.6198 28.7121 19.5841 27.0975C19.7821 24.4341 19.8399 21.7621 19.7572 19.0926C19.7151 17.692 19.777 6.11935 19.4121 4.42499C19.6617 4.99885 19.9493 5.55542 20.273 6.09102C21.1264 7.42464 24.473 9.30974 26.0771 16.3908C27.3268 23.6943 23.4801 28.86 20.6525 30.8153Z" fill={color[3]} />
                </g>
                <defs>
                <clipPath id="clip0_343_14">
                <rect width="39" height="39" fill="white"/>
                </clipPath>
                </defs>
            </svg>
        </div>
    );
}

export default MongoIcon;