import { useState } from "react";

const JsIcon = () => {
    const [color, setColor] = useState('#94aab9');

    return(
        <div className="flex justify-center items-center">
            <svg width="35" height="35" viewBox="0 0 106 106" fill="none" xmlns="http://www.w3.org/2000/svg" className="cursor-pointer" onMouseEnter={() => {setColor('#FFE500')}} onMouseLeave={() => {setColor('#94aab9')}}>
                <path d="M0 0V106H106V0H0ZM57.6867 82.6686C57.6867 92.9847 51.6296 97.6941 42.8051 97.6941C34.8324 97.6941 30.2176 93.5791 27.8515 88.5857L35.9681 83.687C37.5316 86.4544 38.9512 88.7977 42.3811 88.7977C45.6444 88.7977 47.7265 87.5181 47.7265 82.5286V48.6691H57.6867V82.6686ZM81.249 97.6941C71.9967 97.6941 66.0115 93.2951 63.1003 87.5181L71.2169 82.8352C73.3482 86.3143 76.1383 88.8924 81.037 88.8924C85.1521 88.8924 87.8059 86.8329 87.8059 83.9709C87.8059 80.5638 85.1066 79.3561 80.5411 77.3459L78.0576 76.2821C70.8648 73.2309 66.1099 69.3732 66.1099 61.2566C66.1099 53.7799 71.8112 48.1013 80.6849 48.1013C87.026 48.1013 91.5689 50.3008 94.8359 56.074L87.0752 61.0446C85.3716 57.9934 83.5242 56.7857 80.6622 56.7857C77.7548 56.7857 75.9074 58.6331 75.9074 61.0446C75.9074 64.0278 77.7548 65.2316 82.0364 67.1018L84.5199 68.1656C92.9923 71.7847 97.7471 75.5023 97.7471 83.8271C97.7471 92.7727 90.6944 97.6904 81.2528 97.6904L81.249 97.6941Z" fill={color} />
            </svg>
        </div>
    );
}

export default JsIcon;
