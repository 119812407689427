import { useState } from "react";

const TsIcon = () => {
    const [color, setColor] = useState('#94aab9');
    
    return(
        <div className="flex justify-center items-center">
            <svg width="35" height="35" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" className="cursor-pointer" onMouseEnter={() => {setColor('#74C0FC')}}
        onMouseLeave={() => {setColor('#94aab9')}}>
                <g clip-path="url(#clip0_330_16)">
                <mask id="mask0_330_16" maskUnits="userSpaceOnUse" x="0" y="0" width="60" height="60">
                <path d="M0 0H60V60H0V0Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_330_16)">
                <path d="M58.5725 2.19317e-06H1.425C1.04684 0.000664476 0.684391 0.151354 0.417223 0.41899C0.150055 0.686627 1.61711e-06 1.04934 2.19704e-06 1.4275V58.575C-0.000327136 58.7625 0.0363715 58.9483 0.107993 59.1217C0.179615 59.295 0.284752 59.4525 0.417373 59.5851C0.549994 59.7177 0.707491 59.8229 0.880832 59.8945C1.05417 59.9661 1.23995 60.0028 1.4275 60.0025H58.575C58.7625 60.0025 58.9481 59.9656 59.1213 59.8938C59.2945 59.8221 59.4518 59.7169 59.5844 59.5844C59.7169 59.4518 59.8221 59.2945 59.8938 59.1213C59.9656 58.9481 60.0025 58.7625 60.0025 58.575V1.425C60.0022 1.23754 59.9649 1.05198 59.8929 0.878911C59.8208 0.705845 59.7154 0.548662 59.5826 0.416339C59.4498 0.284015 59.2923 0.179142 59.119 0.107707C58.9456 0.0362717 58.76 -0.000326399 58.5725 2.19317e-06ZM35.715 32.065H28.5725V54.285H22.8575V32.065H15.715V27.1425H35.715V32.065ZM37.315 53.015V47.0775C37.315 47.0775 40.56 49.5225 44.4525 49.5225C48.345 49.5225 48.195 46.9775 48.195 46.6275C48.195 42.935 37.165 42.935 37.165 34.75C37.165 23.62 53.2375 28.0125 53.2375 28.0125L53.0375 33.3025C53.0375 33.3025 50.3425 31.505 47.2975 31.505C44.2525 31.505 43.155 32.955 43.155 34.5C43.155 38.4925 54.285 38.095 54.285 46.13C54.285 58.505 37.315 53.0175 37.315 53.0175" fill={color}/>
                </g>
                </g>
                <defs>
                <clipPath id="clip0_330_16">
                <rect width="60" height="60" fill="white"/>
                </clipPath>
                </defs>
            </svg>
        </div>

    );
}

export default TsIcon;